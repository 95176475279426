import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'
import 'default-passive-events'

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

import { appDirective } from "./utils/directive"

const app = createApp(App);
appDirective(app)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(Vue3DraggableResizable);
app.use(Vue3ColorPicker)

const globalData = ref({
  userIsVip: false,   //是否是vip
  vipType: "",       //vip的类型
  hasPhone: false,    //是否绑定了手机号
  newRepairId: 0,  //生成视频时的repairId
  validCustImage: false,  //是否可以形象定制
});

app.provide('globalData', globalData);


app.mount('#app')





