import { eventReport } from "@/api/api";
// 通过自定义指定，给页面的点击事件埋点
const appDirective = app => {
  app.directive("tracking", {
    mounted(el, binding, vnode) {
      if (binding.value.blockRequest) {
        // 删除草稿不需要上报数据
        return;
      }
      el.addEventListener("click", () => {
        eventReport({
          eventType: 'click',
          eventCode: uuid(),
          eventName: binding.value.eventName
        })
          .then((res) => {
            if (res.data) {
            }
          })
      });
    },
    unmounted(el, binding) {
      el && el.removeEventListener("click", () => {
        eventReport({
          eventType: 'click',
          eventCode: uuid(),
          eventName: binding.value.eventName
        })
          .then((res) => {
            if (res.data) {
            }
          })
      });
    }
  });
};
// 用于生成唯一标识
function uuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}

export { appDirective };