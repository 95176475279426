<template>
  <router-view />
</template>
<script setup>
import { onMounted } from "vue";
function isMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|iPhone|iPad|iPod|blackberry|iemobile|opera mini/i.test(userAgent);
}

onMounted(() => {
  if (isMobileDevice()) {
    alert('请在电脑端打开此网页，手机端请使用【Amemori数字人】小程序');
  }
});
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-size: 16px;
}
a {
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
input {
  border: none;
  outline: none;
}
</style>
